<template>
  <div class="promocodes-page">
    <BackTitle class="mb-40" @click="goBack">Promocodes</BackTitle>
    <Guard permission="promocodes.create" v-slot="{ isAvailable }">
      <IconButton
        v-if="isAvailable"
        icon="plus"
        isRaised
        class="mb-40"
        @click="goToCreation"
      >
        Create promocode
      </IconButton>
    </Guard>
    <div class="promocodes-page__content">
      <Loader v-if="isLoading" size="m" color="primary" class="mx-auto" />
      <div v-else-if="!promocodes.length" class="empty">
        You haven't created promocodes yet
      </div>
      <PromocodesTable
        v-else
        :promocodes="promocodes"
        @on-row-click="handleOpenPromocode"
      />
    </div>
  </div>
</template>

<script>
import BackTitle from "@/components/common/BackTitle.vue";
import Guard from "@/components/common/Guard.vue";
import { mapActions, mapGetters, mapState } from "vuex";
import PromocodesTable from "@/components/promocodes/PromocodesTable.vue";

export default {
  name: "PromocodesPage",
  components: { PromocodesTable, Guard, BackTitle },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapState({
      promocodes: (state) => state.promocodes.list,
    }),
    ...mapGetters({
      checkPermission: "users/checkPermission",
    }),
  },
  async created() {
    try {
      this.isLoading = true;
      await this.fetchPromocodes();
    } finally {
      this.isLoading = false;
    }
  },
  methods: {
    ...mapActions({
      fetchPromocodes: "promocodes/fetchPromocodes",
      deletePromocode: "promocodes/deletePromocode",
    }),
    goBack() {
      this.$router.push({
        name: "Settings",
      });
    },
    goToCreation() {
      this.$router.push({
        name: "CreatePromocode",
      });
    },
    handleOpenPromocode(id) {
      this.$router.push({
        name: "EditPromocode",
        params: {
          id,
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.promocodes-page__content {
  display: flex;
  flex-direction: column;
}
</style>
