<template>
  <vue-good-table
    :columns="tableColumns"
    :rows="promocodes"
    class="default-table"
    styleClass="promocodes-table"
    @on-row-click="$emit('on-row-click', $event.row.id)"
  />
</template>

<script>
import { PromoRateTypeEnum, TaxBehaviourEnum } from "@/helpers/enums";
import moment from "moment/moment";
import { mapState } from "vuex";

export default {
  name: "PromocodesTable",
  props: {
    promocodes: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tableColumns: [
        {
          label: "Code",
          field: "code",
        },
        {
          label: "Number of uses",
          field: (promocode) => {
            return promocode.bookings?.length || 0;
          },
          type: "number",
        },
        {
          label: "Max number of uses",
          field: "maxUseNumber",
          type: "number",
        },
        {
          label: "Active",
          field: (promocode) => {
            return promocode.isActive ? "Yes" : "No";
          },
        },
        {
          label: "Validity dates",
          field: (promocode) => {
            return promocode.from || promocode.to
              ? [
                  promocode.from
                    ? moment(promocode.from).format(this.dateFormat)
                    : "∞",
                  promocode.to
                    ? moment(promocode.to).format(this.dateFormat)
                    : "∞",
                ].join(" - ")
              : "Permanent";
          },
        },
        {
          label: "Discount",
          field: (promocode) => {
            if (promocode.discountType === PromoRateTypeEnum.FIXED) {
              return new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits: 2,
              }).format(Math.abs(promocode.discount));
            } else if (promocode.discountType === PromoRateTypeEnum.PERCENT) {
              return `${promocode.discount}%`;
            }
            return promocode.discount;
          },
        },
        {
          label: "Tax behaviour",
          field: (promocode) => {
            return {
              [TaxBehaviourEnum.BEFORE]: "Before taxes",
              [TaxBehaviourEnum.AFTER]: "After taxes",
            }[promocode.taxBehaviour];
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      dateFormat: (state) => state.venues.selectedVenue?.dateFormat,
    }),
  },
};
</script>

<style lang="scss">
@import "@/assets/styles/table.scss";

.promocodes-table {
}
</style>
